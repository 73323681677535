import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import SEO from '../components/organisms/seo';
import RichText from '../components/atoms/richtext';
import AboutVideo from '../assets/videos/about.mp4';
import { media } from '../utils/media-queries';

const AboutPage = ({ data }) => {
  const {
    _rawDescription,
    vita,
    past,
    mail,
    previewImage,
    phone,
    social,
  } = data.about.nodes[0];

  const [vidLoaded, setVidLoaded] = useState();

  return (
    <>
      <SEO siteTitle="About" />
      <StyledAbout>
        <div className="content">
          <div className="desc">
            <RichText blocks={_rawDescription} />
          </div>
          <div className="vita">
            {vita.map((vitaItem, i) => (
              <div key={i} className="year">
                <h2>{vitaItem.year}</h2>
                <div className="large">
                  <RichText blocks={vitaItem._rawDescription} />
                </div>
              </div>
            ))}
          </div>
          <div className="past">
            {past.map((pastItem, i) => (
              <div key={i}>
                <h2>{pastItem.title}</h2>
                {pastItem.item.map((item, itemI) => (
                  <p key={itemI} className="large">
                    {item}
                  </p>
                ))}
              </div>
            ))}
          </div>
          <div className="contact">
            <h2>Contact</h2>
            <a href={`mailto:${mail}`} className="large mail">
              {`${mail.split('@')[0]}@`}
              <br className="mobile" />
              {`${mail.split('@')[1]}`}
            </a>
            <br />
            <a className="large phone" href={`tel:${phone}`}>
              {phone}
            </a>
          </div>
          <div className="social">
            <h2>Social</h2>
            {social.map((item) => (
              <div className="large">
                <a href={item.url} target="_blank" rel="noopener noreferrer">
                  {item.title}
                </a>
              </div>
            ))}
          </div>
        </div>

        <div className="video">
          <figure>
            <video
              src={AboutVideo}
              playsInline
              loop
              muted
              autoPlay
              preload="auto"
              onLoadedData={() => setVidLoaded(true)}
            />
          </figure>
          {!vidLoaded && (
            <figure className="preview-img">
              <GatsbyImage
                image={previewImage.asset.gatsbyImageData}
                alt="Video Preview Image"
              />
            </figure>
          )}
        </div>
      </StyledAbout>
    </>
  );
};

const StyledAbout = styled.section`
  padding: var(--sp-1-3);
  margin-top: var(--sp-5);
  display: flex;
  flex-direction: column;

  .phone,
  .mail,
  .social a {
    display: inline-block;
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.3;
    }
  }

  .social {
    padding-bottom: var(--sp-3);
  }

  h2 {
    text-transform: uppercase;
  }

  .preview-img {
    position: absolute;
    top: 0;
  }

  .content {
    order: 2;
    padding-right: 15vw;

    .desc {
      p {
        padding-bottom: var(--sp-2);
      }
    }

    .past {
      h2 {
        padding-top: var(--sp-5-8);
      }
    }

    .vita {
      padding-top: calc(var(--sp-5-8) - var(--sp-2) - var(--sp-1));

      .year {
        padding-top: var(--sp-1);
      }
    }

    .contact {
      padding-top: var(--sp-5-8);
    }
  }

  .video {
    position: relative;
    padding-bottom: var(--sp-3);
    order: 1;
    height: 100vw;
  }

  .phone {
    padding-bottom: var(--sp-5);
  }

  @media ${media.L} {
    margin-top: var(--sp-8);
    flex-direction: row;

    .content,
    .video {
      order: 0;
      width: 50%;
    }

    .contact {
      .mobile {
        display: none;
      }
    }

    .video {
      position: fixed;
      right: var(--sp-1-3);
      padding-left: var(--sp-1-3);
      padding-bottom: 0;
    }

    .phone {
      padding-bottom: var(--sp-3);
    }
  }
`;

export const query = graphql`
  query About {
    about: allSanityAbout {
      nodes {
        _rawDescription
        mail
        phone
        social {
          title
          url
        }
        previewImage {
          asset {
            gatsbyImageData
          }
        }
        vita {
          year
          _rawDescription
        }
        past {
          title
          item
        }
      }
    }
  }
`;

AboutPage.propTypes = {
  data: PropTypes.object,
};

export default AboutPage;
